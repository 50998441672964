<template>
  <div class="card card-custom col-lg-13 col-xxl-12 mt-5 pt-5">
    <div class="d-flex align-items-center">
      <b-button
      @click="$bvModal.show('modal-interaction-form')"
        variant="outline-primary"
        pill
      >
        Créer une interaction
      </b-button>
      <b-modal id="modal-interaction-form" ref="modal" title="Créer une interaction" @show="resetModal" @hidden="resetModal" @ok="validForm">
        <form
            ref="form"
            >
        <b-form-group
          label="Nom"
          label-for="title-input"
          invalid-feedback="Veuillez saisir un nom"
          :state="newItemFormState.name"
        >
          <b-form-input id="title-input" v-model="newItemForm.name" :state="newItemFormState.name" required></b-form-input>
        </b-form-group>
        <b-form-group
          label="Fichier"
          label-for="file-input"
          :invalid-feedback="`${
              !newItemForm.file ? 'Veuillez choisir un fichier' : 'Veuillez choisir un format de fichier valide'
          }`"
          :state="newItemFormState.file"
        >
          <b-form-file
              id="file-input"
              accept=".zip"
              v-model="newItemForm.file"
              :state="newItemFormState.file"
              browse-text="Parcourir"
              placeholder="Choisisez un fichier ou déposez-le ici"
              drop-placeholder="Deposer le fichier"
          />
          <b-form-text id="input-live-help">Formats acceptés : zip.</b-form-text>
          <div class="mt-3 ml-2 font-italic text-muted">
              Nom du fichier selectionné : {{ newItemForm.file ? newItemForm.file.name : '' }}
          </div>
        </b-form-group>
        </form>
        <template #modal-footer="{ok,cancel}">
          <div class="w-100">
            <div class="float-right" disabled>
              <b-button variant="secondary" size="sm" @click="cancel()">Annuler</b-button>
              <b-button variant="primary" class="mx-1" size="sm" @click="ok()" >Valider</b-button>
            </div>
          </div>
        </template>
      </b-modal>
    </div>
    <b-table
        responsive
        hover
        no-border-collapse
        style="vertical-align: middle; text-align: center"
        class="mx-0 p-0"
        :class="mobile ? 'mb-5 mt-0' : 'my-5'"
        :items="interactiveItems"
        :fields="fields">
      <template #cell(Action)="row">
        <div class="d-flex flex-row justify-content-around align-items-center">
          <b-button class="btn btn-icon btn-circle btn-light btn-sm" @click="$bvModal.show('edit-modal-' + row.item.id), itemForm.name = row.item.name, itemForm.table = row.item.table">
              <b-icon icon="pencil-square"></b-icon>
          </b-button>
          <div>
            <b-modal :id='"edit-modal-" + row.item.id' title="Modifier l'interaction" @show="resetModal" @hidden="resetModal" @ok="updateInteraction(row.item.id)">
              <div>
                <b-form-group
                    label="Nom de l'intéraction"
                    label-for="name-input"
                    invalid-feedback="Veuillez saisir un nom"
                >
                  <b-form-input id="name-input" :value="row.item.name" v-model="itemForm.name"></b-form-input>
                </b-form-group>
                <b-form-group
                    label="Fichier"
                    label-for="file-input"
                    :invalid-feedback="`${
                                !itemForm.file ? 'Veuillez choisir un fichier' : 'Veuillez choisir un format de fichier valide'
                            }`"
                >
                  <b-form-file
                      ref="file" id="file" name="file"
                      accept=".zip"
                      v-model="itemForm.file"
                      browse-text="Parcourir"
                      placeholder="Choisisez un fichier ou déposez-le ici"
                      drop-placeholder="Deposer le fichier"
                  />
                  <b-form-text id="input-live-help">Formats acceptés : zip.</b-form-text>
                  <div class="mt-3 ml-2 font-italic text-muted">
                    Nom du fichier selectionné : {{ itemForm.file ? itemForm.file.name : '' }}
                  </div>
                </b-form-group>
              </div>
            </b-modal>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { LIST_INTERACTIVEITEMS } from '@/core/services/store/api/interactive-items.service';
import { mapGetters } from 'vuex';
import ApiService from '@/core/services/api.service';

export default {
  data() {
    return {
      interactions: null,
      fields: [
        { key: 'id', label: 'id', sortable: true },
        { key: 'name', label: 'Nom', sortable: true },
        { key: 'media_name', label: 'Fichier', sortable: true },
        { key: 'Action', label: '', sortable: false }
      ],
      itemForm: {
        name: null,
        table: "interactive_",
        file: null,
      },
      newItemForm: {
        name: null,
        table: "interactive_",
        file: null,
      },
      newItemFormState: {
        name: null,
        table: null,
        file: null,
      }
    };
  },
  computed: {
    ...mapGetters({ interactiveItems: 'getInteractiveItems' }),
    mobile() {
      return window.screen.availWidth > 992 ? false : true;
    }
  },
  mounted() {
    this.getInteractions();
  },
  methods: {
    getInteractions() {
      this.$store.dispatch(LIST_INTERACTIVEITEMS);
    },
    resetModal() {
      this.itemForm.name= null,
      this.itemForm.table= "interactive_",
      this.itemForm.file= null,
      this.newItemForm.name = null,
      this.newItemForm.file = null
      this.newItemFormState.name = null,
      this.newItemFormState.file = null
    },
    updateInteraction(id) {
      let data = new FormData();
      data.append('id', id);

      if (this.itemForm.name !== null) {
        data.append('name', this.itemForm.name);
      }

      if (this.itemForm.table !== 'interactive_') {
        data.append('table', this.itemForm.table);
      }

      if (this.itemForm.file !== null) {
        data.append('file', this.itemForm.file);
      }

      ApiService.post("academy/interaction/"+ id, data).then(() => {
        this.getInteractions();
      });
    },
    validForm(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$bvModal.hide('modal-interaction-form');
      this.create();
    },
    checkFormInputsValidity() {
      const valid = this.$refs.form.checkValidity();

      if (this.newItemForm.name) {
        this.newItemFormState.name = valid;
      } else {
        this.newItemFormState.name = false;
      }

      if (this.newItemForm.file && this.newItemForm.file.type === 'application/x-zip-compressed') {
        this.newItemFormState.file = valid;
      } else {
        this.newItemFormState.file = false;
      }

      return valid;
    },
    create() {
      if (!this.checkFormInputsValidity()) {
        return;
      } else {
        this.$nextTick(() => {
          let formDataItem = new FormData();
          formDataItem.append('name', this.newItemForm.name);
          formDataItem.append('type', 'interactives_' + this.newItemForm.name);
          formDataItem.append('file', this.newItemForm.file);
          formDataItem.append('fileUrl', URL.createObjectURL(this.newItemForm.file));
          ApiService.post(process.env.VUE_APP_API_URL + '/academy/interaction', formDataItem).then(() => {
            this.$bvModal.hide('modal-interaction-form');
            this.getInteractions();
          })
        })
      }
    }
  }
};
</script>
<style>
td {
  vertical-align: middle !important;
}
</style>
